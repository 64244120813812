import { Container, Typography, makeStyles } from "@material-ui/core";
import MissingInfo from "../../Global/assets/images/missing_info.png";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  icon: {
    width: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },

  infoHeader: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem ",
    },
  },

  infoText: {
    marginTop: "1rem",
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function ConfigurationErroView(props) {
  const classes = useStyles();

  return (
    <Container variant="main" maxWidth="lg" className={classes.container}>
      <img src={MissingInfo} alt="missing_info" className={classes.icon} />
      {props.validation &&
      props.validation.missingParams && 
      props.validation.missingParams.length > 0 ? (
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          {props.validation.missingParams
            .map((missingParam) => `'${missingParam}'`)
            .join(" , ")}{" "}
          {props.validation.missingParams.length > 1
            ? "parameters"
            : "parameter"}{" "}
          not found!
        </Typography>
      ) : props.validation.invalidParams && props.validation.invalidParams.length > 0 ? <Typography variant="h3" component="h3" className={classes.infoHeader}>
      {props.validation.invalidParams
        .map((invalidParam) => `'${invalidParam}'`)
        .join(" , ")}{" "}
      {props.validation.missingParams.length > 1
        ? "parameters are "
        : "parameter is "}{" "}
      invalid!
    </Typography> :
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          Parameters not found!
        </Typography>
      }
      <Typography variant="body1" component="p" className={classes.infoText}>
        Looks like the neccessary required parameters were not passed to the URL
        or were not valid. Make sure to have the right navigation link when
        trying to access this page.
      </Typography>
      {props.validation && props.validation.initialUrl && props.validation.initialUrl.length > 0 && <Typography variant="body1" component="p" className={classes.infoText} style={{whiteSpace: "pre-wrap"}}>
        The navigation link you provided is: <br />
        <i title={props.validation.initialUrl}>{props.validation.initialUrl.length > 100 ? props.validation.initialUrl.slice(0,100) + "..." : props.validation.initialUrl }</i>
      </Typography>}
      <Typography variant="body1" component="p" className={classes.infoText}>
        The navigation link should have the following structure:{" "}
        <i>
          https://xporter-base-url.com?environment=<span style={{color: "#1976d2"}}>[validEnvironmentValue (Test,OTE,PROD)]</span>&schemaName=<span style={{color: "#1976d2"}}>[validSchemaName]</span>&records=<span style={{color: "#1976d2"}}>[listOfRecordIds]</span>
        </i>
      </Typography>
    </Container>
  );
}
