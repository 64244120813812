import axios from "./RequestInterceptor";
import ReactDOM from "react-dom";
import { logout } from "../Services/LoginService";
import { clearToken } from "../Utilities/CookieManager";
import DisplayError from "../Components/ErrorPopup/ErrorPopup";

axios.interceptors.response.use(
  (resConfig) => {
    if (resConfig.status === 401 || resConfig.status === 403) {
      handleResponseError(
        `Request failed with status code ${resConfig.status}`
      );
      logout();
      return;
    }
    return resConfig;
  },
  (responseErrorConfig) => {
    handleResponseError(responseErrorConfig.message);

    if(responseErrorConfig.message.includes("401")) {
      logout();
    }
  }
);

function handleResponseError(message) {
  ReactDOM.render(
    <DisplayError errorMessage={message} />,
    document.getElementById("root")
  );
}

export default axios;
