import {Fragment} from 'react';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import MyoshLogo from '../../Global/assets/images/myosh-logo.png'
import "./Header.css";

export default function Footer() {
    return <AppBar position="absolute" color="default">
    <Toolbar>
        <img src={MyoshLogo} className="myosh-logo"></img>
    </Toolbar>
  </AppBar>
}