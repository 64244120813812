export const environments = {
    Test: {
      urlTemplate: ":endpointBaseUrl/:schema/:endpointVersion",
      endpointBaseUrl: "https://testapi.myosh.com/",
      endpointVersion: "v3/",
      apiKey: "cA1wLu7VLY9XBIudzBZ6S3Ae8OQ5M4yn7s4vV8qs"
    },
    OTE: {
      navigationBaseUrl: "/",
      urlTemplate: ":endpointBaseUrl/:schema/:endpointVersion",
      endpointBaseUrl: "https://oteapi.myosh.com/",
      endpointVersion: "v3/",
      apiKey: "qmt1zrZu9xuMakrUYtVC40Gp4c1aGIH52J2Nq3li"
    },
    PROD: {
      navigationBaseUrl: "/",
      urlTemplate: ":endpointBaseUrl/:schema/:endpointVersion",
      endpointBaseUrl: "https://api.myosh.com/",
      endpointVersion: "v3/",
      apiKey: "qmt1zrZu9xuMakrUYtVC40Gp4c1aGIH52J2Nq3li"
    },
  };