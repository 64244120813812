import axios from 'axios';
import {getToken} from '../Utilities/CookieManager';
import {handleError} from "../Components/ErrorPopup/ErrorPopup";


const headers = {"Content-Type": "application/json"}

const instance = axios.create()

instance.interceptors.request.use((reqConfig) => {
    const token = getToken();
    const basicAuthorization = localStorage.getItem("basicAuthorization");
    if(token && token.length > 0) {
        headers["Authorization"] = token;
    }

    if(basicAuthorization) {
        headers["BasicAuthorization"] = basicAuthorization;
    }

    reqConfig.headers = headers;

    return reqConfig;
}, (err) => {
    handleError(err.message);
})


export default instance;