import { logout } from "../Services/LoginService";
import { environments } from "../Setup/setup";
import { handleError } from "../Components/ErrorPopup/ErrorPopup";
import REG_EXP from "./Regexp";

/**
 *
 * @param {{name: string, value: string | boolean}[]} queryParams A function that retrieves an array with queryParams
 * in object form and return a single string that can be attached to the end of any URL
 */

export function buildQueryGroup(queryParams) {
  return queryParams.length > 0
    ? queryParams
        .map((queryParam, index) => {
          if (index > 0) {
            return `&${queryParam.name}=${queryParam.value}`;
          } else {
            return `?${queryParam.name}=${queryParam.value}`;
          }
        })
        .join("")
    : null;
}

/**
 *
 * @param {string} queryString A function that gets a query string and returns an array of
 * query params containing their names and values
 */

export function transformQueryString(queryString) {
  if (queryString && queryString.length > 0) {
    let queryArray = queryString.split(/[\?|\&]/);
    queryArray.shift();

    queryArray = queryArray.map((query) => {
      const keyValueSet = query.split("=");

      if (keyValueSet[0].includes("records")) {
        return {
          name: keyValueSet[0],
          value: keyValueSet[1] && keyValueSet[1].includes(",")
            ? [...keyValueSet[1].split(",")]
            : [keyValueSet[1]],
        };
      }

      return { name: keyValueSet[0], value: keyValueSet[1] && keyValueSet[1].trim() };
    });
    return queryArray;
  } else {
    return null;
  }
}

/**
 *
 * @param {string} queryString A function that gets a query string and returns whether this params are valid or not
 * according to the required parameters
 */

export function validateQueryParams(queryString, translation) {
  let requiredParams = ["environment", "schemaName", "records"];
  const myEnvironments = Object.keys(environments);
  const queryParams = transformQueryString(queryString);
  const missingParams = [];
  const invalidParams = [];

  if (queryParams) {
    let validation = false;
    validation = queryParams.reduce((acc, val) => {
      if (requiredParams.includes(val.name)) {
        acc = acc && true;
        if (
          val.name === "environment" &&
          !myEnvironments
            .map((env) => env.toLowerCase())
            .includes(val.value.toLowerCase())
        ) {
          invalidParams.push(val.name);
          acc = acc && false;
        }

        if (val.name === "schemaName" && val.value.length === 0) {
          invalidParams.push(val.name);
          acc = acc && false;
        }

        if (val.name === "records") {
          if (val.value.length === 1 && val.value[0].length === 0) {
            invalidParams.push(val.name);
            acc = acc && false;
          } else {
            const containsLetter = val.value.some((v) => {
              return REG_EXP.LETTER_VALIDATOR.test(v);
            });
            if (containsLetter) {
              invalidParams.push(val.name);
              acc = acc && false;
            }
          }
        }
      }
      return acc;
    }, true);

    const userParams = queryParams.map((param) => param.name);
    requiredParams.forEach((param) => {
      if (!userParams.includes(param)) {
        validation = false;
        missingParams.push(param);
      }
    });

    validateTokenAuthenticity(queryParams, translation);

    if (validation) saveURLData(queryParams);

    return { validation, missingParams, invalidParams };
  } else {
    return { validation: false, missingParams: [], invalidParams: [] };
  }
}

/**
 *
 * @param {*} queryParams A function that saves the information retrieved from the URL to the local storage
 */

export function saveURLData(queryParams) {
  for (let query of queryParams) {
    localStorage.setItem(query.name, query.value);
  }
}

export function buildLoginURL() {
  let environment;
  if (localStorage.getItem("environment")) {
    environment = Object.keys(environments).find(
      (env) =>
        env.toLowerCase() === localStorage.getItem("environment").toLowerCase()
    );
  } else {
    return null;
  }

  return environment && environment.length > 0
    ? environments[environment].endpointBaseUrl +
        environments[environment].endpointVersion +
        "login"
    : null;
}

export function buildUrl(requestedObject, queryParams) {
  let environment;
  let schema;
  if (
    localStorage.getItem("environment") &&
    localStorage.getItem("schemaName")
  ) {
    environment = localStorage.getItem("environment");
    schema = localStorage.getItem("schemaName");
  } else {
    return null;
  }

  const environmentKeys = Object.keys(environments).filter(
    (env) => env !== "OTE" && env !== "PROD"
  );

  let query = buildQueryGroup(queryParams);
  if (environment && schema) {
    return environmentKeys.includes(environment)
      ? environments[environment].endpointBaseUrl +
          environments[environment].endpointServerName +
          schema +
          "/" +
          environments[environment].endpointStackName +
          environments[environment].endpointVersion +
          requestedObject +
          query
      : environments[environment].endpointBaseUrl +
          environments[environment].endpointVersion +
          schema +
          "/" +
          requestedObject +
          query;
  }
  return null;
}

/**
 *
 * @param {*} queryParams
 * @returns {boolean}
 */

export function validateTokenAuthenticity(queryParams, translation) {
  const incomingEnvironment = queryParams.find(
    (query) => query.name === "environment"
  );

  if (
    incomingEnvironment &&
    incomingEnvironment.value &&
    incomingEnvironment.value.length > 0
  ) {
    const currentEnvironment = localStorage.getItem("environment");

    const currentUserSchemas = JSON.parse(localStorage.getItem("userSchemas"));
    const incomingSchema = localStorage.getItem("schemaName");

    if (!currentUserSchemas || currentUserSchemas.length === 0) {
      return true;
    }

    if (
      incomingEnvironment.value.toLowerCase() === currentEnvironment.toLowerCase() &&
      currentUserSchemas
        .map((user) => user.toLowerCase())
        .includes(incomingSchema)
    ) {
      return true;
    } else {
      handleError(translation("loginView.errorMessages.invalidToken"));
      logout();
      return false;
    }
  }
}
