import { Fragment, useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import "./Progress.css";
import { Button, Typography, makeStyles } from "@material-ui/core";
import CircularProgressWithLabel from "../CircularProgressWithLabel/CircularProgressWithLabel";
import { ArrowDownward } from "@material-ui/icons";
import keys from "../../Setup/keys";
import { useTranslation } from "react-i18next";


const myKeys = keys();

const useStyles = makeStyles((theme) => ({
  progressBar: {
    marginTop: "10px",
  },
  header: {
    marginBottom: "1rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ProgressView() {
  const { t, i18n } = useTranslation();
  const downloadLinkRef = useRef();

  let socket;
  const classes = useStyles();
  const [state, setState] = useState({
    header: t('progress.headerOne'),
    progress: 0,
    message: t('progress.infoExporting'),
    error: false,
    success: false,
  });

  const [downloadUrl, setDownloadUrl] = useState("");

  const startDownload = () => {
    const {current} = downloadLinkRef;
    current.click();
  };

  const resetState = () => {
    setState((oldState) => ({ ...oldState, success: false, error: false }));
  };

  const getProgressMessage = (progressData) => {
     if (progressData.zip) {
      return t(`progress.infoExportZipInProgress`);
    } else {
      return t('progress.infoExportUrlInProgress');
    }
  }

  const createSocket = () => {
    return io(myKeys.SERVER_API_KEY, {
      forceNew: true,
      transports: [ "polling" ],
      extraHeaders: { "Access-Allow-Control-Origin": "*" },
      withCredentials: true
    });
  } 

  useEffect(() => {
    socket = createSocket();
    socket.on("progressChunk", (progressData) => {
      if (progressData.progress >= 100) {
        setDownloadUrl(progressData.url);
        socket.disconnect();
        window.dispatchEvent(new Event("download-complete"));
        setState({ ...state, header: t('progress.headerTwo') });
        setState((oldState) => ({
          ...oldState,
          success: true,
          message: t('progress.infoExportSuccess'),
        }));
        return;
      }
      setState((oldState) => ({
        ...oldState,
        progress: progressData.progress,
        message: progressData.recordId ? (t('progress.infoExporting') +
          t('progress.infoExportInProgressOne') +
          progressData.schemaName +
          t('progress.infoExportInProgressTwo') +
          progressData.recordId) : (t('progress.infoExporting') + getProgressMessage(progressData)),
      }));
    });

    return () => {
      resetState(); 
      socket.disconnect();
    };
  }, []);

  return (
    <Fragment>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {state.header}
      </Typography>
      <div className="linear-progress">
        <p className={state.error ? "error" : "progress"}>{state.message}</p>
        {!state.error && !state.success ? (
          <CircularProgressWithLabel
            value={state.progress}
            className={classes.progressBar}
          />
        ) : (
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={startDownload}
              startIcon={<ArrowDownward />}
            >
              <a ref={downloadLinkRef} style={{ color: "#fff" }} href={downloadUrl}>{t('progress.download')}</a>
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
}
