import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Copyright from "../../Components/Copyright/Copyright";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import ErrorContainer from "../../Components/ErrorContainer/ErrorContainer";
import { startLogin } from "../../Services/LoginService";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  let [state, setState] = useState({
    username: "",
    password: "",
    showPassword: false,
    isLoading: false,
    error: false,
    errorMessage: null,
  });

  const history = useHistory();

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value, error: false });
  };

  const handlePasswordVisibility = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const toggleLoading = () => {
    setState({ ...state, isLoading: !state.isLoading });
  };

  const handleErrorMessage = (error, message) => {
    setState((oldState) => ({
      ...oldState,
      error,
      errorMessage: message,
      isLoading: false,
    }));
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    handleErrorMessage(false, null);
    toggleLoading();
    const loginData = await startLogin(state.username, state.password, t);
    if (loginData.error) {
      handleErrorMessage(true, loginData.payload.errorMessage);
    } else {
      toggleLoading();
      history.push("main" + location.search);
    }
  };

  const onKeyDown = async (event) => {  
    if(event.key === "Enter") { 
      await handleLogin();
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("loginView.signIn")}
        </Typography>
        <form className={classes.form} noValidate onKeyDown={onKeyDown}>
          <TextField
            variant="outlined"
            label={`${t("loginView.username")} *`}
            placeholder={`${t("loginView.usernameEnter")}`}
            className={classes.input}
            onChange={handleChange("username")}
          />
          <TextField
            variant="outlined"
            label={`${t("loginView.password")} *`}
            placeholder={`${t("loginView.passwordEnter")}`}
            type={state.showPassword ? "text" : "password"}
            className={classes.input}
            onChange={handleChange("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={handlePasswordVisibility}
                    onMouseDown={handleMouseDown}
                  >
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {state.error ? (
            <ErrorContainer errorMessage={state.errorMessage} />
          ) : (
            ""
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              handleLogin();
            }}
          >
            {state.isLoading ? t("loginView.loggingIn") : t("loginView.logIn")}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
