import {Fragment} from 'react';
import {CssBaseline, Grid, makeStyles} from '@material-ui/core';
import {ErrorOutline} from '@material-ui/icons';
import "./ErrorContainer.css";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: "2.5rem",
        paddingTop: "0.5rem",
    }
}))

export default function ErrorContainer({errorMessage}) {
    const classes = useStyles();

    return <Fragment>
        <CssBaseline />
        <div className="error-container">
            <Grid container alignItems="center">
                <Grid item xs={2}><ErrorOutline className={classes.icon}/></Grid>
                <Grid item xs={10}>{errorMessage}</Grid>
            </Grid>
        </div>
    </Fragment>
}