import { Switch, Route, Redirect } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import Header from "./Components/Header/Header";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { getToken } from "./Utilities/CookieManager";
import LoginView from "./Views/LoginView/LoginView";
import CardContainerView from "./Views/CardContainerView/CardContainerView";
import ConfigurationErrorView from "./Views/ConfigurationErrorView/ConfigurationErrorView";
import NotFoundView from "./Views/NotFoundView/NotFoundView";
import "./App.css";

const requireLogin = (to, from, next) => {
  const params = from.location.search;

  if (to.match.path.includes("login") && getToken()) {
    next.redirect("/main" + params);
  } else if (to.match.path.includes("login") && !getToken()) {
    next();
  } else if (to.match.path.includes("main") && !getToken()) {
    next.redirect("/login" + params);
  } else if (to.match.path.includes("main") && getToken()) {
    next();
  } else {
    next.redirect("*" + params);
  }


};

const theme = createMuiTheme({
  palette: {
    primary: { main: "#1976d2" },
  },
});

function OverrideCSS({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

function App() {
  return (
    <OverrideCSS>
      <div className="App">
        <Header />
        <GuardProvider guards={[requireLogin]} error={NotFoundView}>
          <Switch>
            <Route path="/" render={handleEmptyPathRedirection} exact />
            <GuardedRoute path="/login" component={LoginView} exact />
            <GuardedRoute path="/main" component={CardContainerView} exact />
            <GuardedRoute
              path="/invalid"
              component={ConfigurationErrorView}
              exact
            />
            <GuardedRoute path="*" component={NotFoundView} />
          </Switch>
        </GuardProvider>
      </div>
    </OverrideCSS>
  );
}


function handleEmptyPathRedirection(fromRouteData) {
  const { location: { search } } = fromRouteData;

  return getToken() ? <Redirect to={`/main${search}`} /> : <Redirect to={`/login${search}`} />
}

export default App;
