import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ParamGuard from "./Components/Guards/ParamGuard/ParamGuard";
import "./i18n/i18n";

export default function Main({children}) {
  return (
    <Router>
      <ParamGuard>
        <App />
        {children}
      </ParamGuard>
    </Router>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
