/**
 *
 * @param {string} token
 * @throws {error}
 */

export function setToken(token) {
  if (token && token.length > 0) {
    localStorage.setItem("token", token);
  } else {
    throw new Error("A token was not provided.");
  }
}

/**
 * @returns {string | null}
 */

export function getToken() {
  const token = localStorage.getItem("token");
  return token || null;
}

export function clearToken() {
  localStorage.removeItem("token");
}
