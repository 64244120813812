import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    box: {
        marginTop: "1rem"
    }
})

const CircularProgressWithStyles = withStyles((theme) => ({
    svg: {
        width: "60px",
        margin: "auto"
    }
}))(CircularProgress);

export default function CircularProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex" className={classes.box}>
      <CircularProgressWithStyles variant="determinate" {...props} />
      <Box
        top={-10}
        left={20}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="h6" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}