import axios from "../Interceptors/Shared";
import keys from '../Setup/keys';

const myKeys = keys();

function getResult({ error, errorMessage, data }) {
  return arguments[0];
}

function composeResponse(payload) {
  if (payload && payload.hasOwnProperty("message")) {
    return getResult({error: true, errorMessage: payload.message, data: null});
  } else if (payload &&
    payload.hasOwnProperty("data") &&
    typeof payload.data !== "string"
  ) {
    return getResult({error: false, errorMessage: null, data: payload.data});
  } else {
    return getResult({error: true, errorMessage: "Missing data in the response object.", data: null});
  }
}

async function startExport({
  environment,
  schemaName,
  records,
  configurationOptions,
}) {
  const url = myKeys.SERVER_API_KEY + "/export";
  let response;

  try {
    response = await axios({ method: "POST", url, withCredentials: true, baseURL: myKeys.SERVER_API_KEY, data: arguments[0] });
  } catch (error) {
    return composeResponse(error);
  }

  if(response && response.data && typeof response.data !== "string") {
    return composeResponse(response);
  }
  
}

export { startExport };
