import { setToken, clearToken } from "../Utilities/CookieManager";
import { buildLoginURL } from "../Utilities/UrlManager";
import { environments } from "../Setup/setup";
import jwt_decode from "jwt-decode";

const typeEnum = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

/**
 *
 * @param {string} username
 * @param {string} password
 */


export async function startLogin(username, password, translation) {
  let response;
  let url = buildLoginURL();
  const environment = Object.keys(environments).find(env => env.toLowerCase() === localStorage.getItem("environment").toLowerCase());

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Origin": "*"
  };


  if (environment && environment.length > 0) {
    headers["X-Api-Key"] = environments[environment].apiKey;
  } else {
    composeResponse(typeEnum.ERROR, translation("loginView.errorMessages.missingHeaders"))
  }

  if (username && password) {
    const basicAuthorization = "Basic " + btoa(username + ":" + password);
    localStorage.setItem("basicAuthorization", basicAuthorization);
    headers["Authorization"] = basicAuthorization;
  } else {
    return composeResponse(typeEnum.ERROR, translation("loginView.errorMessages.missingCredentials"));
  }

  try {
    response = await fetch(url, {
      method: "POST",
      headers,
      mode: "cors"
    });

    if (response.ok) {
      const data = await response.json();
      if (data.token) {
        const schemaName = localStorage.getItem("schemaName");
        if (validateCurrentSchema(data.token, schemaName)) {
          setToken(data.token);
          return composeResponse(typeEnum.SUCCESS, data);
        } else {
          return composeResponse(
            typeEnum.ERROR,
            translation("loginView.errorMessages.schemaAccessErrorOne") + username + translation("loginView.errorMessages.schemaAccessErrorTwo") + schemaName
          );
        }
      }
      return composeResponse(
        typeEnum.ERROR,
        translation("loginView.errorMessages.authorization")
      );
    } else if (!response.ok) {
      return composeResponse(typeEnum.ERROR, translation("loginView.errorMessages.incorrectCredentials"));
    }
  } catch (err) {
    return composeResponse(typeEnum.ERROR, translation("loginView.errorMessages.unknown"));
  }
}

/**
 * 
 * @param {*} type 
 * @param {*} payload 
 */

function composeResponse(type, payload) {
  return {
    error: type === typeEnum.ERROR,
    payload: {
      errorMessage: typeof payload === "string" ? payload : "",
      data: typeof payload === "object" ? payload : {},
    },
  };
}


/**
 * 
 * @param {*} token 
 * @param {*} currentSchemaName 
 */

function validateCurrentSchema(token, currentSchemaName) {
  let userSchemas;
  let valid = false;
  if (token && token.length > 0) {
    const userData = jwt_decode(token);
    if (userData.hasOwnProperty("schemas")) {
      userSchemas = userData.schemas;
      userSchemas.includes(currentSchemaName)
        ? (valid = true)
        : (valid = false);
    } else {
      valid = false;
    }
  }

  valid && localStorage.setItem("userSchemas", JSON.stringify(userSchemas));
  return valid;
}



export function logout() {
  localStorage.clear();
  clearToken();
  window.location.replace("/login" + window.location.search);
}