import { useState, useEffect } from "react";
import {
  CssBaseline,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Copyright from "../../Components/Copyright/Copyright";
import Configuration from "../../Components/Configuration/Configuration";
import Progress from "../../Components/Progress/Progress";
import Stepper from "../../Components/Stepper/Stepper";
import { Fragment } from "react";
import "./CardContainerView.css";
import { useTranslation } from "react-i18next";

function getComponent(activeStep, handleNext, handleBack) {
  if (activeStep < 1) {
    return <Configuration handleNext={handleNext} />;
  } else return <Progress/>;
}

function getSteps(translation) {
  return [{id: 0, label: translation("utilities.stepper.stepSettings")}, {id: 1, label: translation("utilities.stepper.stepExport")}, {id: 2, label: translation("utilities.stepper.stepDownload")}];
}

const useStyles = makeStyles((theme) => ({
  layout: {
    paddingTop: theme.spacing(20),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
}));

export default function CardContainer() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const {t, i18n} = useTranslation();
  const steps = getSteps(t);

  const handleNext = () => {
    setActiveStep((prevStep) => {
      if (prevStep >= steps.length) {
        return steps.length;
      }
      return activeStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      if (prevStep <= steps.length) {
        return 0;
      }
      return activeStep - 1;
    });
  };


  useEffect(() => {
    window.addEventListener("download-complete", () => {
      setActiveStep(steps.length);
    })
  })

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Xporter
          </Typography>
          <Stepper steps={steps} activeStep={activeStep} />
          {getComponent(activeStep, handleNext, handleBack)}
        </Paper>
        <Copyright />
      </main>
    </Fragment>
  );
}
