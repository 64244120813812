import React, { Fragment, useState, useEffect } from "react";
import { transformQueryString } from "../../Utilities/UrlManager";
import { useLocation } from "react-router-dom";
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import { startExport } from "../../Services/RecordService";
import "./Configuration.css";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import keys from '../../Setup/keys';

const myKeys = keys();

const useStyle = makeStyles((theme) => ({
  header: {
    marginBottom: "1rem",
  },
  infoIcon: {
    alignSelf: "start",
    marginTop: "-0.1rem",
    width: "65%" 
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  infoText: {
      marginBottom: "1rem",
      color: "rgb(94, 94, 94)"
  }
}));

function ConfigurationView({ handleNext }) {
  const location = useLocation();
  const classes = useStyle();
  const {t, i18n} = useTranslation();
  const [options, setOptions] = useState({
    environment: "",
    schemaName: "",
    records: [],
    configurationOptions: {
      linkedRecords: false,
      attachments: true,
    },
  });

  const handleChange = (event) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      configurationOptions: {
        ...prevOptions.configurationOptions,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleSubmit = async () => {
    await axios.get(myKeys.SERVER_API_KEY, {
      withCredentials: true,
      baseURL: myKeys.SERVER_API_KEY
    });

    handleNext();
    const response = await startExport(options);
  };

  useEffect(() => {
    const query = transformQueryString(location.search);
    if (query)
      query.forEach((q) => {
        setOptions((previousProps) => ({
          ...previousProps,
          [q.name]: q.value,
        }));
      });
    window.addEventListener("export", () => {
      handleSubmit();
    });
  }, []);

  return (
    <Fragment>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {t("configuration.title")}
      </Typography>
      <div className="note-container">
        <p className={classes.infoText}>
        {t("configuration.info")}
        </p>
      </div>
      <Grid item xs={12}>
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              color="primary"
              checked={options.configurationOptions.linkedRecords}
              name="linkedRecords"
              onChange={handleChange}
            />
          }
          label={t("configuration.linkedRecords")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={options.configurationOptions.attachments}
              name="attachments"
              onChange={handleChange}
            />
          }
          label={t("configuration.attachments")}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            {" "}
            {t("configuration.export")}{" "}
          </Button>
        </div>
      </Grid>
    </Fragment>
  );
}

export default ConfigurationView;
