export default {
  en: {
    translation: {
      loginView: {
        signIn: "Sign In",
        username: "Username",
        usernameEnter: "Enter an username",
        password: "Password",
        passwordEnter: "Enter a password",
        logIn: "Log In",
        loggingIn: "Logging in...",
        errorMessages: {
          authorization: "An authorization token was not found.",
          missingCredentials: "Missing Credentials",
          incorrectCredentials: "Incorrect username or password",
          unknown: "An error occured",
          schemaAccessErrorOne: "User with username: ",
          schemaAccessErrorTwo: " does not have access to schema: ",
          missingHeaders: "Missing Authorization Header.",
          invalidToken: "Invalid Token"
        },
      },
      configuration: {
        title: "Choose settings",
        info: "This add-on allows you to create a zipfile containing PDF versions of the records selected in your Viking record list.\n\n  You can choose to also include attachments, and/or to additionally include PDF versions of any linked records as well.\n\n  Note that including attachments and linked records extends the time needed to generate the zipfile.",
        linkedRecords: "Include linked records",
        attachments: "Include attachments",
        export: "Export"
      },
      progress: {
        headerOne: "Export",
        headerTwo: "Download your data",
        infoExporting: "Exporting records...  You can close this browser window and we will send you an email when the zipfile is ready to download.",
        infoExportInProgressOne: "\n  Currently processing record from schema ",
        infoExportInProgressTwo: " with id #",
        infoExportAttachmentsInProgress: "\n Fetching attachments...",
        infoExportZipInProgress: "\n Zipping files...",
        infoExportUrlInProgress: "\n Generating url...",
        infoExportSuccess: "Zipfile successfully generated! \n Please check the log file inside the zipfile for more details.",
        back: "Back",
        download: "Download"
      },
      utilities: {
        copyright: {
          developed: "Developed By",
        },
        stepper: {
          stepSettings: "Choose settings",
          stepExport: "Export",
          stepDownload: "Download your data"
        }
      }
    },
  },
};
