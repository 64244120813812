import {Typography, Link} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function Copyright() {
    const {t, i18n} = useTranslation();
    
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {t("utilities.copyright.developed") + " © "}
        <Link color="inherit" href="https://continuumworks.com">
          Continuum Works
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
}