import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  withStyles
} from "@material-ui/core";
import "./Stepper.css";


const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
  }
}));

const StepLabelWithStyles = withStyles((theme) => ({
  label: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  active: {
    textShadow: "0px 0.2px 0.6px black"
  }
}))(StepLabel)



export default function StepperProp({steps, activeStep}) {
  const classes = useStyles();
  return (
    <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((step) => (
              <Step key={step.id}>
              <StepLabelWithStyles>{step.label}</StepLabelWithStyles>
              </Step>
            ))}
    </Stepper>
  );
}
