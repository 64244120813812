import { Container, Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    placeItems: "center",
    placeContent: "center",
    flexDirection: "row"
  },
  responsiveText: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
}));

export default function NotFoundView() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <Typography
        variant="h2"
        component="h2"
        color="primary"
        className={classes.responsiveText}
      >
        404
      </Typography>
      <Typography
        variant="h2"
        component="h2"
        className={classes.responsiveText}
      >
        NOT FOUND
      </Typography>
    </Container>
  );
}
