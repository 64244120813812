import { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useHistory, Route} from 'react-router-dom';
import {validateQueryParams} from '../../../Utilities/UrlManager';
import ConfigurationErrorView from '../../../Views/ConfigurationErrorView/ConfigurationErrorView';

export default function ParamGuard({children}) {
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [validationObject, setValidationObject] = useState({});

    useEffect(() => {
        const validationPayload = validateQueryParams(location.search, t);
        setValidationObject({...validationPayload, initialUrl: window.location.href})
        if(!validationPayload.validation) {
            history.push("/invalid");
        }
    }, [])

    return validationObject.validation && validationObject.validation ? children : <Route path="/invalid" render={() => <ConfigurationErrorView validation={validationObject}/>} exact/>
    ;
}